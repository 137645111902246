<script setup>
// import ArrowLine from '~/assets/icons/arrow-line.svg';

// Define props with the defineProps method
const props = defineProps({
    form: {
        type: Object,
        default: () => ({}),
    },
    page: {
        type: Object,
        default: () => ({}),
    },
    pageIndex: {
        type: Number,
        default: 0,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    modelValue: {
        type: Number,
        default: 0,
    },
    settings: {
        type: Object,
        default: () => ({}),
    },
});

// Define emits
const emit = defineEmits(['update:modelValue']);

// Reactive references for reactive data properties
const activePage = ref(props.modelValue);

// Watching modelValue prop to update activePage
watch(() => props.modelValue, (newValue) => {
    activePage.value = newValue;
});

// Computed properties
const position = computed(() => {
    if (props?.settings?.buttonsPosition === 'left-right') {
        return 'justify-between';
    }
    if (props?.settings?.buttonsPosition === 'center') {
        return 'justify-center';
    }
    if (props?.settings?.buttonsPosition === 'right') {
        return 'justify-end';
    }
    return '';
});

// Methods
const onBack = () => {
    emit('update:modelValue', activePage.value - 1);
};

</script>

<template>
    <div v-show="pageIndex === activePage">
        <div class="grid grid-cols-1 gap-6 bg-perle text-black lg:grid-cols-2">
            <div v-if="form?.settings?.displayCurrentPageTitle ?? false">
                <h3 class="mb-6 text-7xl font-medium  leading-6 text-gray-900">
                    {{ page.name }}
                </h3>
            </div>
            <template
                v-for="(row, rowIndex) in page.rows"
                :key="rowIndex"
            >
                <FormieField
                    v-for="(field, fieldIndex) in row.rowFields"
                    :key="fieldIndex"
                    :field="field"
                />
            </template>
        </div>
        <div class="mt-3">
            <div class="flex" :class="position">
                <button
                    v-if="settings?.settings?.showBackButton"
                    type="button"
                    class="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    v-on:click.prevent="onBack"
                >
                    {{ settings?.settings?.backButtonLabel }}
                </button>
                <button
                    :disabled="loading"
                    type="submit"
                    class="ml-auto flex cursor-pointer items-center rounded border border-carmin px-2 py-1 font-sans text-xl font-light  uppercase text-encre transition hover:bg-carmin hover:text-white lg:text-lg"
                    :class="{ 'loading': loading }"
                >
                    {{ settings?.settings?.submitButtonLabel }}
                    <span class="ml-2 inline-block">
                        <svg
                            width="19"
                            height="16"
                            viewBox="0 0 19 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="pointer-events-none"
                        >
                            <path
                                d="M15.9435 1.3584H2.66039C1.74338 1.3584 1 2.10178 1 3.01879V12.9812C1 13.8982 1.74338 14.6416 2.66039 14.6416H15.9435C16.8606 14.6416 17.6039 13.8982 17.6039 12.9812V3.01879C17.6039 2.10178 16.8606 1.3584 15.9435 1.3584Z"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M17.6039 3.84912L10.1571 8.58124C9.90077 8.74183 9.60443 8.82699 9.30197 8.82699C8.99952 8.82699 8.70317 8.74183 8.44687 8.58124L1 3.84912"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>

                    </span>
                </button>
            </div>
        </div>
    </div>
</template>
